.container {
  padding: 15px;
}

.content {
  background-color: white;
  height: 100vh;
  border-radius: 5px;
  padding: 20px;
}

.filter {
  border-radius: 5px;
  background-color: white;
  padding: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: 0px 1px 2px -1px;
  -moz-box-shadow: 0px 1px 2px -1px;
  -webkit-box-shadow: 0px 1px 2px -1px;
}

.table {
  background-color: white;
  padding: 0px 5px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.firstInput {
  border-radius: 4px 0px 0px 4px !important;
}

.buttonSearch {
  width: 10%;
  border-radius: 0px !important;
}

.buttonNew {
  width: 10%;
  border-radius: 0px 4px 4px 0px !important;
}

#customRow {
  display: flex;
  align-items: flex-end;
}

#customRow .ant-form-item-label {
  white-space: normal;
  line-height: initial;
  margin-bottom: 11px;
}

.customRadioGroup {
  position: relative;
  display: inline-flex;
  width: 100%;
  bottom: 1px;
}

.customRadioGroup.campaignQuota label {
  max-width: fit-content;
}

.customInputNumber.campaignQuota {
  max-width: 150px !important;
}

.time-period-filed-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.time-period-filed-wrapper span {
  white-space: nowrap;
}

.customRadioGroup .ant-radio-button-wrapper {
  flex: 1;
  text-align: center;
}

.verticalRadio {
  display: block;
  height: 30px;
  line-height: 30px;
}

.customSelectTags .ant-select-selection__choice {
  color: #434341;
  background: #e6f7ff;
  border: 1px solid #1890ff;
  margin-top: 4px !important;
  margin-bottom: 3px !important;
  margin-right: 7px !important;
}

.customSelectTags .ant-select-selection {
  min-height: 64px;
}

.companyForm {
  background: #f3f2f3;
  border: 1px solid #cececd;
  border-radius: 2px;
  margin-bottom: 28px;
  padding: 0px 16px;
}

.btnRemoveCompany {
  text-decoration-line: underline;
  color: #ff8585;
  opacity: 0.85;
  font-weight: 700;
  cursor: pointer;
}

.btnAddCompany {
  text-decoration-line: underline;
  color: #7a84bf;
  opacity: 0.85;
  font-weight: 700;
  cursor: pointer;
}

.custom-input-number {
  width: 200px;
}

.custom-form-item {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.custom-form-item .custom-form-item {
  width: unset;
  overflow: unset;
}

.custom-form-item .ant-col-24 {
  width: unset;
  margin-right: 10px;
}
