.businessHourContainer {
  display: flex;
  flex-direction: column;
  margin: 26px 16px 0px 64px;
}

.titleBusinessHour {
  font-weight: bold;
  font-size: 14px;
  color: #7a84bf;
}

.timezoneContainer {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.titleTimezone {
  font-weight: bold;
}

.rowTimezone {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.timezoneButton {
  width: 60%;
  margin-right: 10%;
}

.weekContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.titleWeek {
  font-weight: bold;
}

.weekButtonsRow {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.buttonChecked {
  padding: 14px;
  margin-right: 12px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #1890ff;
  color: white;
  display: inline-flex;
  align-items: center;
}

.buttonChecked:hover,
.buttonUnchecked:focus {
  padding: 14px;
  margin-right: 12px;
  border-radius: 5px;
  font-size: 14px;
  background-color: white;
  color: #565559;
  display: inline-flex;
  align-items: center;
}

.buttonUnchecked {
  padding: 14px;
  margin-right: 12px;
  border-radius: 5px;
  font-size: 14px;
  background-color: white;
  color: #565559;
  display: inline-flex;
  align-items: center;
}

.buttonUnchecked:hover,
.buttonChecked:focus {
  padding: 14px;
  margin-right: 12px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #1890ff;
  color: white;
  display: inline-flex;
  align-items: center;
}

.weekDaysColumn {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.dayContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 8px;
  background-color: #f3f2f380;
  border: 1px solid #cececd;
}

.rowTitleDay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.titleNameDay {
  font-weight: 700;
}

.titleButtonDay {
  background-color: transparent;
  border: none;
}

.titleButtonDayIcon {
  color: #7a84bf;
}

.titleButtonDayText {
  text-decoration: underline;
  line-height: 16px;
  color: #7a84bf;
  font-weight: bold;
}

.rowTwentyFourHours {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.dayColumnHours {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 8px;
}

.hourContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hourFirstText {
  margin-right: 12px;
}

.hourSecondText {
  margin-left: 12px;
  margin-right: 12px;
}

.hourTimePicker {
  width: 14%;
}

.buttonAddHour {
  background-color: transparent;
  border: none;
}

.buttonDeleteHour {
  background-color: transparent;
  margin-left: 8px;
}

.buttonAddHourIcon {
  color: #7a84bf;
}

.buttonAddHourText {
  text-decoration: underline;
  line-height: 16px;
  color: #7a84bf;
  font-weight: bold;
}

.saveContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
}

.saveButtonDisable {
  padding: 16px;
  margin-right: 12px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #f3f2f3;
  color: #434341;
  display: inline-flex;
  align-items: center;
}

.notSavedWarning {
  border: 1px solid #fcd429;
  border-radius: 5px;
  background-color: #fff9df;
  padding: 5px;
  margin: 8px;
  font-size: 14px;
}

.iconWarning {
  color: #fcd429;
  margin-right: 8px;
}

.saveButton {
  padding: 16px;
  margin-right: 12px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #1890ff;
  color: white;
  display: inline-flex;
  align-items: center;
}

.switchSemiPublicContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.switchSemiPublicRow {
  display: flex;
  flex-direction: row;
}

.switchSemiPublicLabel {
  margin-left: 8px;
  font-size: 14px;
}

.switchSemiPublicInfo {
  color: #747277;
}

.semiPublicHoursContainer {
  display: flex;
  flex-direction: column;
  margin: 8px;
  gap: 8px;
}

.publicHourContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.privateHourContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.publicHourRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.publicHourTitle {
  font-size: 14px;
}

.privateHourRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.privateHourTitle {
  font-size: 14px;
  font-weight: bold;
}

.privateHourTimePicker {
  background-color: #fff9df;
  width: 14%;
}
