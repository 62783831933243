.containerQrCode {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textSwitch {
  margin-left: 8px;
  font-size: 17px;
  font-weight: bold;
}

.qrcode_capture {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 424px;
  height: 424px;
  padding: 12px;
}
