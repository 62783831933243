.drawerInfoTitle {
  font-weight: 500;
  font-size: 21px;
  line-height: 20px;
  color: #434341;
}

.containerSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
}

.paymentStatusTag {
  border-radius: 10px;
}

.paymentStatusTag:nth-child(1 of .paymentStatusTag) {
  margin-left: 16px;
}

.drawerFooter {
  position: absolute;
  right: 20px;
  bottom: 0;
  width: calc(100% - 20px);
  z-index: 99999;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
}

.chargeCard {
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
  border-color: #cececd;
}

.addTagOptions {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 12px;
  padding-left: 12px;
  border: 1px solid #ccc;
  min-width: 110px;
  cursor: pointer;
}

#panel {
  background: #fff;
}

#panel .ant-collapse-content-box {
  padding: 0;
}

#panel .ant-collapse-header {
  padding: 10px 0;
}

#panel .ant-collapse-header i {
  font-size: 14px;
  color: #434341;
}

.statusChanger {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e9e9e9;
}

.statusChanger .ant-select {
  width: 200px;
  margin-right: 10px;
}



