.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
}

.perfil {
  float: right;
  margin-right: 20px;
  display: inline;
}

.app-stage-link {
  font-style: italic;
}

.redirect-link {
  align-items: center;
  color: #ffffffa6;
  display: flex;
  height: 40px;
  margin-left: 48px;
}

.redirect-link:hover {
  color: #ffffff;
}

.tag {
  display: flex;
  border: solid;
  border-radius: 16px;
  margin-left: 5px;
  padding-inline: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 10px;
}

.img-logo {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.neo .ant-layout-sider {
  background: #565759;
}

.neo .ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #565759;
}

.neo .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #333334;
}

.mobilize .ant-layout-sider {
  background: #000000;
}

.mobilize .ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #000000;
}

.mobilize .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #2f2f2f;
}

.shell .ant-layout-sider {
  background: #595959;
}

.shell .ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #595959;
}

.shell .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #7f7f7f;
}

.shell .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #0097a9;
}

.byd .ant-layout-sider {
  background: #686d71;
}

.byd .ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #686d71;
}

.byd .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #4e5356;
}

.byd .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #4f9edd;
}

/* brs */
.brs .ant-layout-sider {
  background: #ffffff;
}

.brs .ant-menu-dark,
.brs .ant-menu-dark > .ant-menu-submenu {
  background: #ffffff;
  color: #505050;
}

.brs
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu-submenu-title
  span {
  color: #035731;
}

.brs
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before {
  background: #035731;
}

.brs
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before {
  background: #505050;
}

.brs
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after {
  background: #035731;
}

.brs
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after {
  background: #505050;
}

.brs
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover {
  color: #035731;
}

.brs
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-item.ant-menu-item-active
  > a:hover {
  color: #035731;
}

.brs .ant-menu-dark.ant-menu-inline > .ant-menu-item a {
  color: #505050;
}

.brs .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #6fb51f;
}

.brs .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #539c00;
}

.brs .ant-menu.ant-menu-dark .ant-menu-item .ant-menu-item-selected a {
  color: #ffffff;
}

/* voltz */
.voltz .ant-layout-sider {
  background: #ffffff;
}

.voltz .ant-menu-dark,
.voltz .ant-menu-dark > .ant-menu-submenu {
  background: #ffffff;
  color: #505050;
}

.voltz
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu-submenu-title
  span {
  color: #29597C;
}

.voltz
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before {
  background: #035731;
}

.voltz
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before {
  background: #505050;
}

.voltz
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after {
  background: #035731;
}

.voltz
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after {
  background: #505050;
}

.voltz
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover {
  color: #29597C;
}

.voltz
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-item.ant-menu-item-active
  > a:hover {
  color: #29597C;
}

.voltz .ant-menu-dark.ant-menu-inline > .ant-menu-item a {
  color: #505050;
}

.voltz .ant-menu-inline-collapsed > .ant-menu-item .anticon {
  color: #505050;
}

.voltz .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #29597C;
}

.voltz .ant-menu-dark .ant-menu-submenu-title:hover {
  color: #143853;
}

.voltz .ant-menu-inline-collapsed > .ant-menu-item:hover .anticon {
  color: #143853;
}

.voltz .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #143853;
}

.voltz .ant-menu.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #FFF;
}

.voltz .ant-menu.ant-menu-dark .ant-menu-item .ant-menu-item-selected a {
  color: #ffffff;
}

/* ion */
.ion .ant-layout-sider {
  background: #ffffff;
}

.ion .ant-menu-dark,
.ion .ant-menu-dark > .ant-menu-submenu {
  background: #ffffff;
  color: #505050;
}

.ion
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu-submenu-title
  span {
  color: #D0CA12;
}

.ion
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before {
  background: #035731;
}

.ion
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before {
  background: #505050;
}

.ion
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after {
  background: #035731;
}

.ion
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after {
  background: #505050;
}

.ion
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover {
  color: #D0CA12;
}

.ion
  .ant-menu-dark.ant-menu-inline
  > .ant-menu-item.ant-menu-item-active
  > a:hover {
  color: #D0CA12;
}

.ion .ant-menu-dark.ant-menu-inline > .ant-menu-item a {
  color: #505050;
}

.ion .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #D0CA12
}

.ion .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #A39E11;
}

.ion .ant-menu.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #FFF;
}

.ion .ant-menu.ant-menu-dark .ant-menu-item .ant-menu-item-selected a {
  color: #ffffff;
}

.ion .ant-menu-inline-collapsed > .ant-menu-item .anticon {
  color: #505050;
}

.ion .ant-menu-inline-collapsed > .ant-menu-item:hover .anticon {
  color: #D0CA12;
}

.ion .ant-menu.ant-menu-dark .ant-menu-item-selected > .ant-menu-inline-collapsed > .ant-menu-item .anticon {
  color: #FFF;
}

.ion .ant-menu-dark .ant-menu-submenu-title:hover {
  color: #D0CA12;
}